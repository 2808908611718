const d3 = require('d3');

// a puzzle for the ages: why is base64, a continuous range of ascii chars, not
// in the same order as ascii? [+/0-9A-Za-z] would make so much more sense.
const base64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiflkmnopqrstuvwxyz0123456789+/";

let canvas = d3.select('#canvas');
let hash = d3.select('#hash');

const seed = "0aaGDZgAAAAAAAAGAYAAAAAAAAPnzZvnz58+AAO3bZu2bt+zAAM2bZs2bB+zAAPmbZvmbBmzAAMGbNsGbBmzAAMAAAM";
let screen = expand(seed);

function flip(n) {
  if (n == 0) {
    return 1;
  }
  return 0;
}

function change(d, i) {
  let p = d3.select(this);

  let x = Math.floor(parseInt(p.attr('x')) / 10)
  let y = Math.floor(parseInt(p.attr('y')) / 10)

  let data = decode(screen);
  let live = data[y * 72 + x];
  data[y * 72 + x] = flip(live);
  screen = encode(data)

  redraw(screen);
}

function* hashdata(hash) {
  // TODO memoize
  let map = {};
  for (let i = 0; i < base64.length; i++) {
    map[base64[i]] = i;
  }

  for (let i = 0; i < hash.length; i++) {
    let charCode = map[hash[i]];
    for (let j = 5; j >= 0; j--) {
      let b = (charCode & (1 << j)) > 0;
      yield b || null;
    }
  }
  return;
}

function redraw(data) {
  let px = canvas.select('#pixels').selectAll('rect').data(hashdata(data));

  px.attr('fill', function(d, n) { return d ? 'black' : 'white' });

  px.enter().append('rect')
    .attr('fill', function(d, n) { return d ? 'black' : 'white' })
    .attr('x', function(d, n) { return 10 * (n % 72) + 1 })
    .attr('y', function(d, n) { return 10 * Math.floor(n / 72) + 1 })
    .attr('width', '8').attr('height', '8')
    .on('click', change);

  hash.text(compress(data));
}

// bits to base64
function encode(data) {
  let result = '';
  for (var i = 0; i < data.length; i+=6) {
    let buffer = data.slice(i, i+6).join('');
    let char = base64[parseInt(buffer, 2)];
    result += char;
  }
  return result;
}

// base64 to bits
function decode(data) {
  // TODO memoize
  let map = {};
  for (let i = 0; i < base64.length; i++) {
    map[base64[i]] = i;
  }

  let result = [];
  for (let i = 0; i < data.length; i++) {
    let index = map[data.charAt(i)];
    let bytes = index.toString(2).padStart(6, '0');
    for (let j = 0; j < 6; j++) {
      result.push(parseInt(bytes.charAt(j)));
    }
  }
  return result;
}

function compress(data) {
  let prefix = 0;
  let suffix = 0;
  for (let i = 0; i < data.length; i++) {
    if (data.charAt(i) != base64[0]) {
      prefix = i;
      break
    }
  }
  for (let i = 1; i <= data.length; i++) {
    if (data.charAt(data.length - i) != base64[0]) {
      suffix = i;
      break
    }
  }
  return prefix.toString(16).padStart(3, '0') + data.substring(prefix, data.length - suffix + 1);
}

function expand(data) {
  let prefix = parseInt(data.substring(0, 3), 16);
  let suffix = (72 * 72 / 6) - prefix - (data.length - 3);
  return base64[0].repeat(prefix) + data.substring(3, data.length) + base64[0].repeat(suffix);
}

redraw(screen);
